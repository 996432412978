<template>
  <v-container fluid ma-0 pa-0>
    <v-layout row wrap max-width-1400 full-width ma-auto>
      <v-flex xs12 py-5 text-xs-center>
        <p class="graphik-medium text-xs-center secondary--text my-3" :class="{'font-28': $vuetify.breakpoint.smAndUp, 'font-20': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_apps_companies_title') }}</p>

        <p class="graphik-light text-xs-center my-3" :class="{'font-18': $vuetify.breakpoint.smAndUp, 'font-16': $vuetify.breakpoint.xsOnly}">{{ $ml.get('content_apps_companies_description') }}</p>

        <v-layout row wrap class="px-5" align-center justify-center>
          <v-img
            v-for="(method, index) in payment_methods"
            :key="index"
            :aspect-ratio="1"
            :class="{'mx-5 my-3': $vuetify.breakpoint.smAndUp, 'mx-2 my-3': $vuetify.breakpoint.xsOnly}"
            contain
            position="center center"
            :src="method.icon"
            :lazy-src="method.icon"
            max-width="100px"
            max-height="100px"
          ></v-img>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ContentPaymentMethods',
  data () {
    return {
      payment_methods: [
        {
          icon: require('@/assets/img/apps/uber.png')
        },
        {
          icon: require('@/assets/img/apps/didi.png')
        },
        {
          icon: require('@/assets/img/apps/cabify.png')
        },
        {
          icon: require('@/assets/img/apps/taxify.png')
        }
      ]
    }
  }
}
</script>
